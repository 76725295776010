import React from "react";
import { Flex, Box, Image, Text, HStack } from "@chakra-ui/react";
import Header from "./Header/Header";
import FooterTest from "./Footer/Footer";

const TeamPage = () => {
  const teamMembers = [
    {
      name: "Emilia",
      role: "Oxford",
      description:
        "Emilia has dedicated the past decade to conducting extensive research at top academic institutions and industry, where she acquired a versatile skill set in various biomedical techniques. Having spent her childhood summers in Norway, she has formed a deep appreciation for preserving biodiversity and protecting our natural ecosystems through innovative and sustainable practices.",
      imageUrl:
        "https://media.licdn.com/dms/image/C4D03AQH1zLlU4DjfQQ/profile-displayphoto-shrink_800_800/0/1615917906038?e=1714003200&v=beta&t=nOCep-aGOruFaM6VRh8XCvdYFwGUKxOqCdh-cC8hRvs", // Replace with actual image URL
    },
    {
      name: "Ian Hsu",
      role: "Oxford",
      description:
        "Ian is an experienced biotech scientist with nearly a decade of research experience. Ian started working as a stem cell researcher at Stanford University and has since been trained in a vast array of biotech research techniques at top global research institutes such as Dartmouth College and University of Oxford. Following ecological field work in Costa Rica in 2022, Ian gained a newfound appreciation for biodiversity, and he has since become an avid proponent for environmental sustainability.",
      imageUrl:
        "https://media.licdn.com/dms/image/C4D03AQHj_KkMduhc0w/profile-displayphoto-shrink_400_400/0/1661532113057?e=1714003200&v=beta&t=RB4fv6dGRmKvpTK6EjOEaH07YQQ7hkNiCLkpvMTXoJU", // Replace with actual image URL
    },
    {
      name: "Allan Rubio",
      role: "Boston",
      description:
        "Allan is a passionate advocate of both life sciences and sustainable social change. During COVID, he co-founded a project to start community biomass power plants around Southeast Asia to combat air pollution.",
      imageUrl:
        "https://media.licdn.com/dms/image/D4E03AQEGdXJ4HHs99A/profile-displayphoto-shrink_400_400/0/1681838332009?e=1714003200&v=beta&t=Ic8wG4gt1OdT5-bCPKPAEc0bDxvo5J_y9PJn-OjZqfc", // Replace with actual image URL
    },
    // Add more team members as needed
  ];

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Header />
      <Flex
        direction="column"
        align="center"
        justify="center"
        minHeight="80vh"
        backgroundColor="#f0f0f0"
      >
        <HStack spacing={8} justify="center">
          {teamMembers.map((member, index) => (
            <Box
              key={index}
              backgroundColor="white"
              borderRadius="8px"
              boxShadow="md"
              p={4}
              width="350px" // Set a fixed width
              height="75vh" // Set a fixed height
            >
              <Image
                src={member.imageUrl}
                alt={member.name}
                borderRadius="full"
                boxSize="150px"
                mx="auto"
              />
              <Text mt={4} textAlign="center" fontWeight="bold" fontSize="lg">
                {member.name}
              </Text>
              <Text mt={2} textAlign="center" color="gray.600">
                {member.role}
              </Text>
              <Text mt={2} textAlign="center" overflow="hidden">
                {member.description}
              </Text>
            </Box>
          ))}
        </HStack>
      </Flex>
      <FooterTest />
    </div>
  );
};

export default TeamPage;
