import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Home.css";
import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  IconButton,
  createIcon,
  IconProps,
  useColorModeValue,
} from "@chakra-ui/react";

import BannerStats from "./BannerStats";
import salmonRoeLanding from "../../assets/images/salmonRoeLanding.jpg";
import roe1 from "../../assets/images/roe1.png";
import roe2 from "../../assets/images/roe2.png";
import roe3 from "../../assets/images/roe3.png";
import ocean from "../../assets/images/ocean.mp4";

const Home = () => {
  const isMobile = window.innerWidth <= 767;

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Header />
      <div className="videoContainer">
        {" "}
        <video
          style={{
            position: "absolute",
            width: "100%",
            height: "100vh",
            objectFit: "cover",
          }}
          autoPlay="autoplay"
          playsInLine="playsinline"
          loop="true"
          muted="true"
        >
          <source src={ocean} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Container
          maxW={"8xl"}
          style={{
            marginTop: "10%",
            marginLeft: "10vh",
            marginBottom: "5vh",
            position: "absolute",
            justifyItems: "center",
            zIndex: 1,
          }}
        >
          <Stack
            backgroundColor="rgba(255, 255, 255, 0.8)"
            style={{ padding: 50, width: "50%" }}
            flex={1}
            spacing={5}
          >
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
            >
              <Text
                as={"span"}
                position={"relative"}
                _after={{
                  content: "''",
                  width: "full",
                  height: "30%",
                  position: "absolute",
                  bottom: 1,
                  left: 0,

                  zIndex: -1,
                }}
                style={{ fontSize: "2.2rem", fontFamily: "Poppins Regular" }}
              >
                Innovations
              </Text>
              <br />
              <Text
                as={"span"}
                position={"relative"}
                _after={{
                  content: "''",
                  width: "full",
                  height: "30%",
                  position: "absolute",
                  bottom: 1,
                  left: 0,

                  zIndex: -1,
                }}
                style={{ fontSize: "2.2rem", fontFamily: "Poppins Regular" }}
              >
                Entrepreneurship
              </Text>
              <br />
              <Text
                style={{ fontSize: "2.2rem", fontFamily: "Poppins Regular" }}
                as={"span"}
                color={"red.400"}
              >
                The future
              </Text>
            </Heading>
            <Text
              style={{ fontSize: "1.3rem", fontFamily: "Poppins Regular" }}
              color={"gray.500"}
            >
              At BioRoe Innovations, we are committed to inspiring and
              empowering businesses to reach their potential and succeed. Our
              mission is to help businesses reach their full potential by
              working together to create lasting, positive change.
            </Text>
            <Stack spacing={4} direction="row">
              <Button
                rounded={"full"}
                size={"lg"}
                fontWeight={"normal"}
                px={6}
                colorScheme={"red"}
                bg={"red.400"}
                _hover={{ bg: "red.500" }}
                style={{ fontFamily: "Poppins Regular" }}
              >
                Contact Us
              </Button>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={"center"}
            align={"center"}
            position={"relative"}
            w={"full"}
          >
            <Box
              position={"relative"}
              height={{ base: "300px", md: "750px" }}
              rounded={"2xl"}
              boxShadow={"2xl"}
              width={"full"}
              overflow={"hidden"}
            >
              <IconButton
                aria-label={"Play Button"}
                variant={"ghost"}
                _hover={{ bg: "transparent" }}
                // icon={<PlayIcon w={12} h={12} />}
                size={"lg"}
                color={"white"}
                position={"absolute"}
                left={"50%"}
                top={"50%"}
                transform={"translateX(-50%) translateY(-50%)"}
              />
            </Box>
          </Flex>
        </Container>
      </div>

      <Stack
        align={"center"}
        spacing={8}
        direction={{ base: "column", md: "row" }}
      >
        {/* First Row - Orange Background, Image */}
        <Flex
          flex={1}
          direction={{ base: "column", md: "row" }}
          spacing={0}
          w={"full"}
        >
          {/* Left Column (Orange Background) */}
          <Flex
            flex={1}
            justify={"center"}
            align={"center"}
            position={"relative"}
            bg={"orange.300"} // Set the background color to orange
          >
            <div style={{ width: "80%" }}>
              <Text
                fontFamily={"Poppins Regular"}
                fontWeight={700}
                textTransform={"uppercase"}
                mb={3}
                fontSize={"xl"}
                color={"white"}
              >
                Problem
              </Text>
              <Heading
                fontFamily={"Poppins Regular"}
                color={"white"}
                mb={5}
                fontSize={{ base: "3xl", md: "5xl" }}
              >
                Salmon industry’s unsustainability
              </Heading>
              <br />
              <Text
                style={{
                  fontSize: "1.25rem",
                  fontFamily: "Poppins Regular",
                  color: "white",
                }}
              >
                We started off as a small group of students frustrated by the
                limited environmentally-sustainable options for seafood
                consumption. In particular, salmon products consistently rate
                among the least environmentally-sustainable seafood options
                because its wild stocks are nearly depleted, and farmed salmon
                is reliant on wild-caught fish feed. However, salmon remain some
                of the most popular fish in the world, for both its taste and
                its cultural significance, making it a difficult food option to
                replace. Thus, we began brainstorming a way to enable
                sustainable salmon consumption.
              </Text>
            </div>
          </Flex>
          {/* Right Column (Image) */}
          <Flex
            flex={1}
            justify={"center"}
            align={"center"}
            position={"relative"}
            w={"full"}
          >
            <Box
              position={"relative"}
              height={{ base: "300px", md: "750px" }}
              boxShadow={"2xl"}
              width={"full"}
              overflow={"hidden"}
            >
              <Image
                alt={"Image"}
                fit={"cover"}
                align={"center"}
                w={"100%"}
                h={"100%"}
                src={roe1}
              />
            </Box>
          </Flex>
        </Flex>
      </Stack>

      <Stack
        flex={1}
        direction={{ base: "column", md: "row" }}
        spacing={0}
        w={"full"}
      >
        {/* Left Column (Image) */}
        <Flex
          flex={1}
          justify={"center"}
          align={"center"}
          position={"relative"}
          w={"full"}
        >
          <Box
            position={"relative"}
            height={{ base: "300px", md: "750px" }}
            boxShadow={"2xl"}
            width={"full"}
            overflow={"hidden"}
          >
            <Image
              alt={"Image"}
              fit={"cover"}
              align={"center"}
              w={"100%"}
              h={"100%"}
              src={roe2}
            />
          </Box>
        </Flex>
        {/* Right Column (Green Background) */}
        <Flex
          flex={1}
          justify={"center"}
          align={"center"}
          position={"relative"}
          w={"full"}
          bg={"green.400"} // Set the background color to green
        >
          <div style={{ width: "80%" }}>
            <Text
              fontFamily={"Poppins Regular"}
              fontWeight={700}
              textTransform={"uppercase"}
              mb={3}
              fontSize={"xl"}
              color={"white"}
            >
              Solution
            </Text>
            <Heading
              fontFamily={"Poppins Regular"}
              color={"white"}
              mb={5}
              fontSize={{ base: "3xl", md: "5xl" }}
            >
              Lab-grown salmon roe
            </Heading>
            <br />
            <Text
              style={{
                fontSize: "1.25rem",
                fontFamily: "Poppins Regular",
                color: "white",
              }}
            >
              Over the past few decades, biotechnology breakthroughs have put us
              on the cusp of producing animal products using just single cells
              as source material. In several fish species, fish eggs have been
              sourced as microscopic embryos and grown in lab settings. We
              reasoned that the same could be done to produce consumption-grade
              salmon roe, and we are now setting off on a journey to find out.
            </Text>
          </div>
        </Flex>
      </Stack>

      <Stack
        align={"center"}
        spacing={8}
        direction={{ base: "column", md: "row" }}
      >
        {/* First Row - Orange Background, Image */}
        <Flex
          flex={1}
          direction={{ base: "column", md: "row" }}
          spacing={0}
          w={"full"}
        >
          {/* Left Column (Orange Background) */}
          <Flex
            flex={1}
            justify={"center"}
            align={"center"}
            position={"relative"}
            bg={"red.300"} // Set the background color to orange
          >
            <div style={{ width: "80%" }}>
              <Text
                fontFamily={"Poppins Regular"}
                fontWeight={700}
                textTransform={"uppercase"}
                mb={3}
                fontSize={"xl"}
                color={"white"}
              >
                Plan
              </Text>
              <Heading
                fontFamily={"Poppins Regular"}
                color={"white"}
                mb={5}
                fontSize={{ base: "3xl", md: "5xl" }}
              >
                Immortalized salmon roe precursor cells
              </Heading>

              <br />
              <Text
                style={{
                  fontSize: "1.25rem",
                  fontFamily: "Poppins Regular",
                  color: "white",
                }}
              >
                Our production plan is to bank a large amount of immortalized
                salmon roe precursor cells, which can be grown into mature
                consumer-ready salmon roe using an in-house cell culture medium.
                This will allow continuous production of salmon roe with minimal
                use of live salmon, thus paving the way for an environmentally
                sustainable method of salmon roe consumption.
              </Text>
            </div>
          </Flex>
          {/* Right Column (Image) */}
          <Flex
            flex={1}
            justify={"center"}
            align={"center"}
            position={"relative"}
            w={"full"}
          >
            <Box
              position={"relative"}
              height={{ base: "300px", md: "750px" }}
              boxShadow={"2xl"}
              width={"full"}
              overflow={"hidden"}
            >
              <Image
                alt={"Image"}
                fit={"cover"}
                align={"center"}
                w={"100%"}
                h={"100%"}
                src={roe3}
              />
            </Box>
          </Flex>
        </Flex>
      </Stack>

      <div style={{ marginBottom: "5vh" }}>
        <BannerStats />
      </div>
      {/* <div className="centerContainer">
        <div className="centerContainerWords">
          Pandanngo sa llaw is a stunning folk dance from the Philippines. Just
          like the graceful movements and synchronized choreography of this
          traditional art form. Our candles release a mesmerizing scent that
          captivates the senses and leaves a lasting impression on anyone who
          encounters its brilliance. With every flicker of the flame, Pandanggo
          candles envelop the room with harmonious blends of exotic tropical
          fruits and luscious flavors, taking you on a sensory journey to the
          enchanting shores of the Philippines. The fragrances dance delicately
          in the air offering a relaxing experience that is both dreamy and
          unforgettable.
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default Home;
