import { ReactNode, useEffect, useState } from "react";
import {
  Stack,
  Container,
  Box,
  Flex,
  Text,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";

import test11 from "../../assets/images/ocean.jpg";

export default function StatsGridWithImage() {
  const [randomImage, setRandomImage] = useState("");

  useEffect(() => {
    // Fetch a random image from Unsplash
    fetch("https://source.unsplash.com/random/800x600")
      .then((response) => {
        setRandomImage(response.url);
      })
      .catch((error) => {
        console.error("Error fetching random image: ", error);
      });
  }, []);

  return (
    <Box bg={"gray.800"} position={"relative"}>
      <Flex
        flex={1}
        zIndex={0}
        display={{ base: "none", lg: "flex" }}
        backgroundImage={test11}
        backgroundSize={"cover"}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        position={"absolute"}
        width={"50%"}
        insetY={0}
        right={0}
      >
        <Flex
          bgGradient={"linear(to-r, gray.800 10%, transparent)"}
          w={"full"}
          h={"full"}
        />
      </Flex>
      <Container maxW={"7xl"} zIndex={10} position={"relative"}>
        <Stack direction={{ base: "column", lg: "row" }}>
          <Stack
            flex={1}
            color={"gray.400"}
            justify={{ lg: "center" }}
            py={{ base: 4, md: 20, xl: 60 }}
          >
            <Box mb={{ base: 8, md: 20 }}>
              <Text
                fontFamily={"Poppins Regular"}
                fontWeight={700}
                textTransform={"uppercase"}
                mb={3}
                fontSize={"xl"}
                color={"gray.500"}
              >
                Who We Are
              </Text>
              <Heading
                fontFamily={"Poppins Regular"}
                color={"white"}
                mb={5}
                fontSize={{ base: "3xl", md: "5xl" }}
              >
                We are Eco Warriors
              </Heading>
              <Text
                fontSize={"xl"}
                color={"gray.400"}
                fontFamily={"Poppins Regular"}
              >
                We started off brainstorming ways to make seafood more
                environmentally sustainable. We quickly realized that salmon roe
                production is one of the most unsustainable foods in modern
                seafood consumption, and together we came up with a plan to
                tackle and reduce waste from salmon roe production.
              </Text>
            </Box>

            {/* <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
              {stats.map((stat) => (
                <Box key={stat.title}>
                  <Text
                    fontFamily={"Poppins Regular"}
                    fontSize={"3xl"}
                    color={"white"}
                    mb={3}
                  >
                    {stat.title}
                  </Text>
                  <Text
                    fontSize={"xl"}
                    color={"gray.400"}
                    fontFamily={"Poppins Regular"}
                  >
                    {stat.content}
                  </Text>
                </Box>
              ))}
            </SimpleGrid> */}
          </Stack>
          <Flex flex={1} />
        </Stack>
      </Container>
    </Box>
  );
}

// Rest of your code (StatsText and stats array)

const StatsText = ({ children }: { children: ReactNode }) => (
  <Text
    as={"span"}
    fontWeight={700}
    color={"white"}
    fontFamily={"Poppins Regular"}
  >
    {children}
  </Text>
);

const stats = [
  {
    title: "35+ companies",
    content: (
      <>
        <StatsText>Ranging from</StatsText> biopharmaceuticals, auto repair
        shops, and more
      </>
    ),
  },
  {
    title: "Your partner",
    content: (
      <>
        <StatsText>I partner</StatsText> with all my portfolio companies with
        advice and friendship
      </>
    ),
  },
  {
    title: "Access to Funding",
    content: (
      <>
        <StatsText>Get funding</StatsText> help and investment help
      </>
    ),
  },
  {
    title: "Thousands+",
    content: (
      <>
        <StatsText>Thousands</StatsText> of people I can connect you with who
        are at my fingertips
      </>
    ),
  },
];
