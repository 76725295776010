import React, { useState } from "react";
import quantumLogo from "../../assets/images/logo.svg";
import "./Header.css";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();

  const navHome = () => {
    navigate("/");
  };
  const isMobile = window.innerWidth <= 767;

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const navTeam = (eventId) => {
    navigate("/team");
  };

  const navCompanies = (eventId) => {
    navigate("/companies");
  };

  const closeMobileMenu = () => {
    setShowMobileMenu(false);
  };

  const mobileContent = (
    <div className="headerContainer">
      <div className="logoContainer">
        <img
          onClick={toggleMobileMenu}
          src={quantumLogo}
          alt="logo"
          style={{ width: 100 }}
        />
        <div
          className="logoFont"
          style={{ fontSize: "1.25em", color: "white", margin: "auto" }}
        >
          Quantum Ideas
        </div>
      </div>
      <div className="menuContainer">
        <div className="menuIcon" onClick={toggleMobileMenu}>
          <div className={`bar ${showMobileMenu ? "open" : ""}`}></div>
          <div className={`bar ${showMobileMenu ? "open" : ""}`}></div>
          <div className={`bar ${showMobileMenu ? "open" : ""}`}></div>
        </div>
      </div>
      {showMobileMenu && (
        <div className="mobileMenu" style={{ opacity: showMobileMenu ? 1 : 0 }}>
          <div className="logoContainer">
            <img
              onClick={toggleMobileMenu}
              src={quantumLogo}
              alt="logo"
              style={{ width: 60 }}
            />
            <div
              className="logoFont"
              style={{ fontSize: "1.25em", color: "white", margin: "auto" }}
            >
              Quantum Ideas Tech
            </div>
            <button
              style={{ color: "white" }}
              className="closeButton"
              onClick={closeMobileMenu}
            >
              X
            </button>
          </div>

          <div style={{ marginTop: "10%" }}>
            <div className="mobileLink">
              <div
                style={{ color: "white" }}
                className="logoFont"
                onClick={navHome}
              >
                Home
              </div>
            </div>
            <div className="mobileLink">
              <div
                style={{ color: "white" }}
                className="logoFont"
                onClick={navCompanies}
              >
                Companies
              </div>
            </div>
            <div className="mobileLink">
              <div
                style={{ color: "white" }}
                className="logoFont"
                onClick={navTeam}
              >
                About Me
              </div>
            </div>

            <div className="mobileLink">
              <div
                style={{ color: "white" }}
                className="logoFont"
                data-tally-open="wzMrNg"
                data-tally-layout="modal"
                data-tally-emoji-text="👋"
                data-tally-emoji-animation="wave"
              >
                Contact Us
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const desktopContent = (
    <div className="centeredHeaderContent">
      <div className="headerContainer">
        <div className="logoContainer">
          <img
            onClick={navHome}
            src={quantumLogo}
            alt="logo"
            style={{ width: 130 }}
          />
        </div>
        <div className="menuContainer">
          <div onClick={navHome} className="logoFont">
            Home
          </div>

          <div onClick={navTeam} className="logoFont">
            The Team
          </div>
          {/* <div
              className="logoFont"
              data-tally-open="wzMrNg"
              data-tally-layout="modal"
              data-tally-emoji-text="👋"
              data-tally-emoji-animation="wave"
            >
              Contact Us
            </div> */}
        </div>
      </div>
    </div>
  );

  return <div>{isMobile ? mobileContent : desktopContent}</div>;
};

export default Header;
