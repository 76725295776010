import React from "react";
import { ReactNode } from "react";
import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import quantumLogo from "../../assets/images/logo.svg";
import { SocialIcon } from "react-social-icons";

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      fontWeight={"500"}
      fontSize={"sm"}
      mb={2}
      color="white"
      fontFamily="Poppins Regular"
    >
      {children}
    </Text>
  );
};

const FooterTest = () => {
  return (
    <Box
      bg={"gray.800"}
      color="white"
      minH={300}
      display="flex"
      alignItems="center"
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 1fr 1fr" }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box href={"/"}>
              <img src={quantumLogo} alt="logo" style={{ width: 150 }} />
            </Box>
            <Text fontSize={"xs"} fontFamily="Poppins Regular">
              © 2023 BioRoe Innovations All rights reserved
            </Text>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Product</ListHeader>
            <Box as="a" href={"/"} color="white" fontFamily="Poppins Regular">
              Home
            </Box>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Company</ListHeader>
            <Box as="a" href={"/"} color="white" fontFamily="Poppins Regular">
              Home
            </Box>
            <Box
              as="a"
              href={"/team"}
              color="white"
              fontFamily="Poppins Regular"
            >
              The team
            </Box>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Support</ListHeader>
            <Box as="a" href={"#"} color="white" fontFamily="Poppins Regular">
              <div
                data-tally-open="wzMrNg"
                data-tally-layout="modal"
                data-tally-emoji-text="👋"
                data-tally-emoji-animation="wave"
              >
                Contact Us
              </div>
            </Box>
          </Stack>
          {/* <Stack align={"flex-start"}>
            <ListHeader>Follow Us</ListHeader>
            <Box as="a" href={"#"} color="white" fontFamily="Poppins Regular">
              <SocialIcon
                url="www.facebook.com"
                style={{ width: 22.5, height: 22.5, marginRight: 10 }}
              />
              Facebook
            </Box>
            <Box as="a" href={"#"} color="white" fontFamily="Poppins Regular">
              <SocialIcon
                url="www.twitter.com"
                style={{ width: 22.5, height: 22.5, marginRight: 10 }}
              />
              Twitter
            </Box>
            <Box as="a" href={"#"} color="white" fontFamily="Poppins Regular">
              <SocialIcon
                url="www.instagram.com"
                style={{ width: 22.5, height: 22.5, marginRight: 10 }}
              />
              Instagram
            </Box>
          </Stack> */}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default FooterTest;
